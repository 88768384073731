import { Injectable, OnDestroy } from '@angular/core';
import { BazisSrvService } from '@bazis/shared/services/srv.service';
import {
    BehaviorSubject,
    combineLatest,
    empty,
    EMPTY,
    merge,
    Observable,
    of,
    shareReplay,
    Subject,
    switchMap,
} from 'rxjs';
import {
    catchError,
    debounceTime,
    filter,
    map,
    mergeMap,
    startWith,
    take,
    tap,
} from 'rxjs/operators';
import { AbstractControl, FormControlStatus, Validators } from '@angular/forms';
import {
    EntityAbstractControl,
    EntityFormControl,
    EntityFormGroup,
    EntityFormArray,
    FormFieldConfig,
    FormSavingStatus,
    FormSettings,
} from '@bazis/form/models/form.types';
import { EntData, EntDescription, EntSchema, SimpleData } from '@bazis/shared/models/srv.types';
import { v4 as uuidv4 } from 'uuid';
import { BazisEntityService } from '@bazis/shared/services/entity.service';
import { BazisStorageService } from '@bazis/shared/services/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BazisDocumentService } from '@bazis/shared/services/document.service';
import moment from 'moment';
import isEqual from 'lodash.isequal';
import { EmailValidators } from '@bazis/form/validators/emailValidators';
import { TemplateObservable } from '@bazis/shared/classes/template-observable';
import { ConditionalValidators } from '@bazis/form/validators/conditionalValidators';
import { BazisToastService } from '@bazis/shared/services/toast.service';
import { SHARE_REPLAY_SETTINGS } from '@bazis/configuration.service';

@Injectable()
export class BazisFormService implements OnDestroy {
    form = new EntityFormGroup({});

    protected formSettings$ = new BehaviorSubject(null);

    protected _lastSaveTimestamp = 0;

    transitDone$ = new Subject();

    transiting = new TemplateObservable(false);

    process$ = new BehaviorSubject('initialization');

    protected loadingFormData$ = this.formSettings$.pipe(
        filter(() => this.form.$config),
        tap((v) => {
            this.process$.next(this._isInited ? 'updating' : 'initialization');
        }),
        mergeMap(([formGroup, forceLoadEntity]) =>
            formGroup.$config.id
                ? this.getEntity$(formGroup.$config, formGroup, forceLoadEntity)
                : this.getMeta$(formGroup.$config, formGroup),
        ),
        tap((v) => {
            this.process$.next(null);
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    form$: Observable<EntityFormGroup> = merge(this.loadingFormData$).pipe(
        filter((v) => this._isInited),
        map(() => this.form),
    );

    protected entityCreated$: Subject<boolean> = new Subject();

    entity$: Observable<EntData> = merge(this.entityCreated$, this.formSettings$).pipe(
        filter(() => this.form.$config),
        switchMap(() =>
            this.form.$config.id
                ? this.entityService.getEntity$(
                      this.form.$config.entityType,
                      this.form.$config.id,
                      {
                          doNotInitLoad: true,
                      },
                  )
                : of(null),
        ),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    id$: Observable<string> = merge(this.form$).pipe(
        filter(() => this._isInited && !this.form.$config.id),
        switchMap(() => this.save(this.form)),
        map(() => this.form.$config.id),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    protected _previousFormValue = null;

    valueChanges$ = this.form$.pipe(
        mergeMap((form) => form.valueChanges),
        startWith(this.form.getRawValue()),
        map((v) => this.form.getRawValue()),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    statusChanges$: Observable<FormControlStatus> = this.form$.pipe(
        mergeMap((form) => form.statusChanges),
        startWith(this.form.status),
        map((v) => this.form.status),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    haveUnsavedChanges$ = this.valueChanges$.pipe(
        debounceTime(300),
        switchMap((value) => {
            if (!this._isInited || !this.form.$config.id) return EMPTY;
            value = this.form.getRawValue();
            const needSave =
                JSON.stringify(value) !== JSON.stringify(this._previousFormValue) &&
                !isEqual(value, this._previousFormValue);
            let formUpdated = false;
            if (this.form.$config.beforeUpdate) {
                formUpdated = this.form.$config.beforeUpdate(
                    this._previousFormValue,
                    this.form.getRawValue(),
                    this.form,
                );
            }

            // console.log(JSON.parse(JSON.stringify(value)));
            // console.log(JSON.parse(JSON.stringify(this._previousFormValue)));
            // console.log(needSave);

            this._setPreviousFormValue(value);
            if (!needSave && !formUpdated) return of(null);
            return of(needSave);
        }),
        startWith(null),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    changes$ = this.haveUnsavedChanges$.pipe(
        filter((needSave) => !!needSave),
        switchMap(() => this.save(this.form)),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    status$ = this.statusChanges$.pipe(shareReplay(SHARE_REPLAY_SETTINGS));

    // FORM SAVING STATUSES LOGIC
    // current form saving status (without children)
    protected _saving$: BehaviorSubject<FormSavingStatus> = new BehaviorSubject(null);

    protected _needUpdateSavingStatus$ = new BehaviorSubject(null);

    // saving status including children statuses
    savingStatus$ = combineLatest([this._saving$, this._needUpdateSavingStatus$]).pipe(
        map(([ownStatus, needUpdate]) => this.checkFormSavingStatus(this.form, ownStatus)),
    );

    syncChanges$ = this.haveUnsavedChanges$.pipe(
        filter((v) => !!v),
        switchMap((v) =>
            this._saving$.pipe(
                filter((saving) => saving?.status !== 'process'),
                take(1),
            ),
        ),
        map(() => true),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    protected _isInited = false;

    constructor(
        protected srv: BazisSrvService,
        protected router: Router,
        protected activatedRoute: ActivatedRoute,
        protected entityService: BazisEntityService,
        protected storageService: BazisStorageService,
        protected documentService: BazisDocumentService,
        protected toastService: BazisToastService,
    ) {}

    ngOnDestroy(): void {
        this.form = new EntityFormGroup({});
        this.form.$config = null;
        this._previousFormValue = null;
        this._setSavingStatus(null);
        this.updateForm();
    }

    protected initField(
        field: string,
        config: FormFieldConfig,
        formGroup: EntityFormGroup,
        isEmpty = false,
    ) {
        if (config.fieldConfig[field].hidden) return;

        const control = isEmpty
            ? this.initEmptyControl(config, field)
            : this.initControl(config, field);
        if (!formGroup.get(field)) {
            formGroup.setControl(field, control);
        } else {
            const currentControl: EntityFormControl = formGroup.get(field) as EntityFormControl;
            const componentValidators = currentControl.$config.componentValidatorFns || [];
            const serviceValidators = currentControl.$config.validators?.map((v) => v.validator);
            currentControl.$config = {
                ...control.$config,
                componentValidatorFns: componentValidators,
            };
            currentControl.setValidators([...serviceValidators, ...componentValidators]);
            if (currentControl.$config.asyncValidators) {
                currentControl.setAsyncValidators([...currentControl.$config.asyncValidators]);
            }
            if (currentControl instanceof EntityFormArray) {
                currentControl.clear({ emitEvent: false });
                control.value.forEach((v) => {
                    currentControl.push(new EntityFormControl(v), { emitEvent: false });
                });
            } else {
                currentControl.setValue(control.value, { emitEvent: false });
            }
            if (currentControl.$config.readonly) {
                currentControl.disable();
            } else {
                currentControl.enable();
            }
            currentControl.markAsPristine();
        }
    }

    protected initForm(formGroup: EntityFormGroup, config: FormFieldConfig, isEmpty = false) {
        if (!isEmpty) {
            const entity = this.entityService.getEntity(config.entityType, config.id);
            if (!entity) return;
        }

        config.fields.forEach((field) => {
            this.initField(field, config, formGroup, isEmpty);
        });

        const processGroups = (groups, groupFormGroup = formGroup) => {
            if (!groups) return;
            groups.forEach((group) => {
                groupFormGroup.setControl(group.id, this.initFormGroup(group));

                if (group.validatorFns)
                    groupFormGroup.get(group.id).setValidators([...group.validatorFns]);

                if (group.asyncValidatorFns)
                    groupFormGroup.get(group.id).setAsyncValidators([...group.asyncValidatorFns]);

                group.fields.forEach((field) => {
                    this.initField(
                        field,
                        config,
                        groupFormGroup.get(group.id) as EntityFormGroup,
                        isEmpty,
                    );
                });

                processGroups(group.groups, groupFormGroup.get(group.id) as EntityFormGroup);
            });
        };

        processGroups(config.groups, formGroup);
        if (config.validatorFns) {
            formGroup.setValidators([...config.validatorFns]);
        }
        if (config.asyncValidatorFns) {
            formGroup.setAsyncValidators([...config.asyncValidatorFns]);
        }
        this._setPreviousFormValue();
        this._isInited = true;
    }

    protected _setPreviousFormValue(value = '') {
        this._previousFormValue = JSON.parse(JSON.stringify(value || this.form.getRawValue()));
    }

    protected generateEntityConfig(schema, config, entity = null) {
        if (!config.fields) {
            config.fields = ['id'];
        }
        const defaultReadOnly =
            entity && entity.$canChange !== undefined ? !entity.$canChange : false;

        const configFields = (fields, targetConfig = config) => {
            fields.forEach((field) => {
                let isRequired = false;

                if (
                    schema.attributes[field] === undefined &&
                    schema.relationships[field] === undefined &&
                    (!targetConfig.fieldConfig[field] ||
                        targetConfig.fieldConfig[field].fieldType !== 'combination')
                ) {
                    targetConfig.fieldConfig[field] = {
                        ...targetConfig.fieldConfig[field],
                        hidden: true,
                    };
                    return;
                }

                const attribute = schema.attributes[field] || {};
                if (schema.attributes[field]) isRequired = !schema.attributes[field].nullable;
                let relationship = schema.relationships[field] || {};
                if (schema.relationships[field])
                    isRequired =
                        !schema.relationships[field].nullable &&
                        schema.relationships[field].type !== 'array';
                if (schema.required.indexOf(field) > -1) isRequired = true;

                if (relationship.entityType) {
                    relationship.validators = isRequired ? [{ type: 'required' }] : [];
                }

                // преобразуем enumDict в options {id; name;}[]
                let options: SimpleData[] = undefined;
                if (attribute.enumDict) {
                    options = Object.keys(attribute.enumDict).reduce((acc, dictItem) => {
                        acc.push({
                            id: dictItem,
                            name: attribute.enumDict[dictItem],
                        });
                        return acc;
                    }, []);
                }

                // use forceRequired - when front wants ignore schema value
                if (targetConfig.fieldConfig[field]?.forceRequired !== undefined) {
                    isRequired = targetConfig.fieldConfig[field].forceRequired;
                }
                if (targetConfig.fieldConfig[field]?.required) {
                    targetConfig.fieldConfig[field].required.set(isRequired);
                }

                targetConfig.fieldConfig[field] = {
                    ...targetConfig.fieldConfig[field],
                    ...attribute,
                    ...relationship,
                    required:
                        targetConfig.fieldConfig[field]?.required ||
                        new TemplateObservable(isRequired),
                    readonly:
                        defaultReadOnly ||
                        !!schema.attributes[field]?.readOnly ||
                        !!schema.relationships[field]?.readOnly,
                    options,
                };

                const schemaValidators = [];
                const schemaValidationParams = ['required', 'maxLength', 'minLength', 'email'];
                schemaValidationParams.forEach((schemaValidationParam) => {
                    if (schemaValidationParam === 'required') {
                        schemaValidators.push({
                            type: schemaValidationParam,
                            value: true,
                        });
                        return;
                    }

                    if (targetConfig.fieldConfig[field][schemaValidationParam]) {
                        schemaValidators.push({
                            type: schemaValidationParam,
                            value: targetConfig.fieldConfig[field][schemaValidationParam],
                        });
                    }
                });

                targetConfig.fieldConfig[field].validators =
                    targetConfig.fieldConfig[field].validators || [];
                schemaValidators.forEach((validator) => {
                    if (
                        !targetConfig.fieldConfig[field].validators.find(
                            (v) => v.type === validator.type,
                        )
                    ) {
                        targetConfig.fieldConfig[field].validators.push(validator);
                    }
                });

                if (targetConfig.fieldConfig[field].fieldType === 'combination') {
                    configFields(
                        targetConfig.fieldConfig[field].fields,
                        targetConfig.fieldConfig[field],
                    );

                    // hide fields combination if one of fields is hidden;
                    targetConfig.fieldConfig[field].hidden = targetConfig.fieldConfig[
                        field
                    ].fields.reduce(
                        (acc, current) =>
                            acc || targetConfig.fieldConfig[field].fieldConfig[current].hidden,
                        false,
                    );
                }

                if (targetConfig.fieldConfig[field].isInnerEntity) {
                    const parentSchema = this.entityService.getParentSchema(
                        schema.schemaType,
                        targetConfig.fieldConfig[field].entityType,
                        null,
                        config.entityType,
                        config.id,
                    );
                    const createSchema = this.entityService.getSchema(
                        'schema_create',
                        targetConfig.fieldConfig[field].entityType,
                    );
                    const innerExistedSchema = parentSchema || createSchema;
                    this.entityService.getParentSchema(
                        'schema_create',
                        targetConfig.fieldConfig[field].entityType,
                        null,
                        config.entityType,
                        config.id,
                    );
                    targetConfig.fieldConfig[field] = this.generateEntityConfig(
                        innerExistedSchema,
                        { ...targetConfig.fieldConfig[field] },
                        null,
                    );
                }
            });
        };

        const configGroups = (groups) => {
            if (!groups) return;
            groups.forEach((group) => {
                configFields(group.fields);
                configGroups(group.groups);
            });
        };

        configFields(config.fields);
        configGroups(config.groups);
        return config;
    }

    protected getSchemaRequest$(schema, config: FormSettings) {
        const schemaRetrieve$ = this.entityService.getSchema$(
            'schema_retrieve',
            config.entityType,
            config.id,
            true,
            config.schemaInclude || [],
        );

        if (config.canUseParentSchema) {
            const parentSchemaRetrieve$ = this.entityService.getParentSchema$(
                'schema_retrieve',
                config.entityType,
                config.id,
                config.parent.entityType,
                config.parent.id,
            );

            return this.entityService
                .getParentSchema$(
                    schema,
                    config.entityType,
                    config.id,
                    config.parent.entityType,
                    config.parent.id,
                )
                .pipe(
                    switchMap((response) =>
                        response.error === 403 ? parentSchemaRetrieve$ : of(response),
                    ),
                );
        }

        return this.entityService
            .getSchema$(schema, config.entityType, config.id, true, config.schemaInclude || [])
            .pipe(
                switchMap((response) => (response.error === 403 ? schemaRetrieve$ : of(response))),
                switchMap((response) => {
                    // если строим вложенные сущности внутри родительской, то нужны схемы вложенных сущностей, если их нет, тогда нужна схема create
                    // поскольку сейчас общую схему transit/update бек не выдает без id, то create должна для таких сущностей быть настроена
                    // по валидации так же, как transit/update
                    const schemaCreateRequests$ = [];
                    if (config.schemaInclude?.length > 0) {
                        config.schemaInclude.forEach((field) => {
                            if (
                                config.fieldConfig[field]?.isInnerEntity &&
                                !this.entityService.getParentSchema(
                                    schema,
                                    response.relationships[field].entityType,
                                    null,
                                    config.entityType,
                                    config.id,
                                )
                            )
                                schemaCreateRequests$.push(
                                    this.entityService.getSchema$(
                                        'schema_create',
                                        response.relationships[field].entityType,
                                    ),
                                );
                        });
                    }
                    return combineLatest([of(response), ...schemaCreateRequests$]);
                }),
                map((response) => response[0]),
            );
    }

    protected getEntity$(config: FormSettings, formGroup: EntityFormGroup, forceLoadEntity = true) {
        return this.entityService
            .getEntity$(config.entityType, config.id, {
                forceLoad: forceLoadEntity,
                include: config.include || [],
            })
            .pipe(
                filter((entityResponse: EntData) => !!entityResponse),
                mergeMap((entityResponse) => {
                    const schema =
                        config.validationSchema[entityResponse.$snapshot.status?.id] ||
                        config.validationSchema.default;
                    let schemaObservable = this.getSchemaRequest$(schema, config);
                    return combineLatest([of(entityResponse), schemaObservable]);
                }),
                filter(
                    ([entityResponse, schemaResponse]: [EntData, EntSchema]) =>
                        !!entityResponse && !!schemaResponse,
                ),
                map(([entityResponse, schemaResponse]: [EntData, EntSchema]) => {
                    // console.log('entity data', entityResponse);
                    // console.log('schema data', schemaResponse);
                    // применяем полученную схему на текущий фронтовый конфиг для формы
                    formGroup.$config = this.generateEntityConfig(
                        schemaResponse,
                        formGroup.$config,
                        entityResponse,
                    );
                    this.initForm(formGroup, formGroup.$config);

                    return entityResponse;
                }),
                take(1),
                catchError((e) => {
                    console.log(e);
                    return of(null);
                }),
            );
    }

    protected getMeta$(config: FormSettings, formGroup: EntityFormGroup) {
        let observable = this.entityService.getSchema$(
            'schema_create',
            config.entityType,
            null,
            false,
            config.schemaInclude || [],
        );
        return observable.pipe(
            tap((response) => {
                if (response) {
                    formGroup.$config = this.generateEntityConfig(response, formGroup.$config);
                }
                this.initForm(formGroup, formGroup.$config, true);
            }),
            take(1),
            catchError((e) => {
                console.log(e);
                return of(null);
            }),
        );
    }

    protected getDefaultValueFromConfig(config: FormFieldConfig): any {
        if (config.initialValue !== undefined) return config.initialValue;
        if (config.default !== undefined) return config.default;
        if (config.nullable) return null;
        if (config.type === 'string') {
            return config.format ? null : '';
        } else {
            return null;
        }
    }

    protected initControl(config: FormFieldConfig, field: string, forceValue = undefined) {
        const entity = this.entityService.getEntity(config.entityType, config.id);
        const fieldConfig = config.fieldConfig[field];
        let value: any = this.getDefaultValueFromConfig(fieldConfig);
        // составляем сложное поле, например, для интервала
        if (fieldConfig && fieldConfig.fieldType === 'combination') {
            value = this.getDefaultValueFromConfig(fieldConfig) || {};
            fieldConfig.fields.forEach((fieldInCombination) => {
                value[fieldInCombination] =
                    forceValue !== undefined
                        ? forceValue
                        : entity.$snapshot[fieldInCombination] || value[fieldInCombination];
                this.initValidators(fieldConfig.fieldConfig);
            });
        } else if (fieldConfig.entityType && fieldConfig.type === 'object') {
            fieldConfig.id = entity.$snapshot[field]?.id;
            value = forceValue !== undefined ? forceValue : fieldConfig.id;
        } else if (fieldConfig.entityType && fieldConfig.type === 'array') {
            fieldConfig.ids = entity.$snapshot[field]
                ? entity.$snapshot[field].map((v) => v.id)
                : [];
            value = fieldConfig.keepArrayInSingleControl
                ? fieldConfig.ids.length > 0
                    ? fieldConfig.ids
                    : value || []
                : fieldConfig.isInnerEntity
                ? fieldConfig.ids.map((id) => this.initInnerFormGroup(fieldConfig, id))
                : fieldConfig.ids.map((id) => new EntityFormControl(id));
        } else {
            let entityValue = entity.$snapshot[field];
            // check !!! this part leads to extra patch because of different zone format
            // if (entityValue && fieldConfig.format === 'date-time') {
            //     entityValue = entityValue.replace('+00:00', 'Z');
            // }
            value =
                forceValue !== undefined
                    ? forceValue
                    : entityValue !== undefined
                    ? entityValue
                    : value;
        }
        return this.generateControl(fieldConfig, value);
    }

    protected initInnerFormGroup(fieldConfig: FormFieldConfig, id = null): EntityFormGroup {
        const formGroup = new EntityFormGroup({});
        formGroup.$config = { ...fieldConfig };
        formGroup.$config.type = 'object';
        if (id) {
            const entity = this.entityService.getEntity(fieldConfig.entityType, id);
            const parentEntity = this.entityService.getEntity(
                this.form.$config.entityType,
                this.form.$config.id,
            );
            const schemaType =
                fieldConfig.validationSchema[
                    entity.$snapshot.status?.id || parentEntity.$snapshot.status?.id
                ] || fieldConfig.validationSchema.default;
            const schema = this.entityService.getSchema(schemaType, fieldConfig.entityType, id);
            const parentSchema = this.entityService.getParentSchema(
                schemaType,
                fieldConfig.entityType,
                id,
                this.form.$config.entityType,
                this.form.$config.id,
            );

            formGroup.$config = this.generateEntityConfig(
                schema || parentSchema,
                formGroup.$config,
                entity,
            );
            formGroup.$config.id = id;
            formGroup.$config.type = 'object';

            this.initForm(formGroup, formGroup.$config);
        } else {
            formGroup.$config.desiredId = uuidv4();

            this.initForm(formGroup, formGroup.$config, true);
        }
        return formGroup;
    }

    protected initEmptyControl(config: FormFieldConfig, field: string) {
        const fieldConfig = config.fieldConfig[field];
        let value: any = this.getDefaultValueFromConfig(fieldConfig);

        if (fieldConfig && fieldConfig.fieldType === 'combination') {
            value = this.getDefaultValueFromConfig(fieldConfig) || {};
            fieldConfig.fields.forEach((fieldInCombination) => {
                value[fieldInCombination] = value[fieldInCombination] || null;
                this.initValidators(fieldConfig.fieldConfig);
            });
        } else if (fieldConfig.entityType && fieldConfig.type === 'array') {
            fieldConfig.ids = [];
            value = value || fieldConfig.ids;
        }
        return this.generateControl(fieldConfig, value);
    }

    protected generateControl(fieldConfig, value) {
        const control =
            fieldConfig.entityType &&
            fieldConfig.type === 'array' &&
            !fieldConfig.keepArrayInSingleControl
                ? new EntityFormArray(value)
                : new EntityFormControl(value);
        this.initValidators(fieldConfig);
        if (!fieldConfig || fieldConfig.fieldType !== 'combination') {
            control.setValidators(fieldConfig?.validators?.map((v) => v.validator));
        }
        if (fieldConfig.asyncValidatorFns) {
            control.setAsyncValidators([...fieldConfig.asyncValidatorFns]);
        }
        control.$config = fieldConfig;
        if (fieldConfig.readonly) {
            control.disable();
        }
        return control;
    }

    protected initValidators(fieldConfig) {
        fieldConfig?.validators?.forEach((validator) => {
            if (validator.type === 'required') {
                validator.validator = ConditionalValidators.required();
            }
            if (validator.type === 'email') {
                validator.validator = EmailValidators.emailValidatorFn();
            }
            if (validator.type === 'maxLength') {
                validator.validator = Validators.maxLength(validator.value);
            }
            if (validator.type === 'minLength') {
                validator.validator = Validators.minLength(validator.value);
            }
            if (validator.type === 'pattern') {
                validator.validator = Validators.pattern(validator.value);
            }
        });
    }

    protected initFormGroup(config: FormFieldConfig) {
        const formGroup = new EntityFormGroup(
            {},
            config.validatorFns ? [...config.validatorFns] : [],
        );
        formGroup.$config = { ...config };

        return formGroup;
    }

    protected generateSaveRequest(form, action = 'change', formId = null) {
        const request = {
            id: formId || null,
            action: action,
            type: form.$config.entityType,
            attributes: {},
            relationships: {},
        };

        const included = [];

        let haveUnfinishedChildCreation = false;

        if (form.$config.parent) {
            const field = form.$config.parent.entityType.split('.');
            // для соединения с полем-родителем надо знать имя св-ва, через которое соединяемся
            // чаще всего это будет вторя часть типа (после точки), но, возможно, и другое поле,
            // которое надо будет явно задать через fieldConnector
            request.relationships[form.$config.parent.fieldConnector || field[1]] = {
                data: {
                    id:
                        form.$config.parent.id ||
                        this.form.$config.id ||
                        this.form.$config.desiredId,
                    type: form.$config.parent.entityType,
                },
            };
        }

        const addFieldsToRequest = (fields, formGroup) => {
            const config = form.$config;
            const formValue = formGroup.getRawValue();
            fields.forEach((field) => {
                if (config?.fieldConfig[field]?.hidden === true) return;

                if (config?.fieldConfig[field]?.entityType) {
                    if (config.fieldConfig[field].type === 'array') {
                        request.relationships[field] = {
                            data: [],
                        };
                        if (config.fieldConfig[field].waitCreation) {
                            const index = formValue[field].findIndex((v) => !v);
                            if (index > -1) {
                                haveUnfinishedChildCreation = true;
                                return;
                            }
                        }

                        if (config.fieldConfig[field].keepArrayInSingleControl) {
                            formValue[field]
                                .filter((arrayItem) => arrayItem)
                                .forEach((arrayItem) => {
                                    request.relationships[field].data.push({
                                        id: arrayItem,
                                        type: config.fieldConfig[field].entityType,
                                    });
                                });
                        } else if (config.fieldConfig[field].isInnerEntity) {
                            formGroup.controls[field].controls.forEach((arrayItemEntity) => {
                                const existedId = arrayItemEntity.$config.id;
                                const formId =
                                    existedId || arrayItemEntity.$config.desiredId || uuidv4();
                                // add entity in include only if it's a new one or it was changed after the latest saving method had started
                                // when change inner entity component MUST add time of changes in config$ property of the entity form group
                                // otherwise entity will not be sent in included
                                if (
                                    !existedId ||
                                    arrayItemEntity.$config?.changedTimestamp >
                                        this._lastSaveTimestamp
                                ) {
                                    const innerRequestData = this.generateSaveRequest(
                                        arrayItemEntity,
                                        existedId ? 'change' : 'add',
                                        formId,
                                    );

                                    included.push(innerRequestData.request);
                                }

                                request.relationships[field].data.push({
                                    id: formId,
                                    type: config.fieldConfig[field].entityType,
                                });
                            });
                        } else if (formGroup.controls[field].controls) {
                            formGroup.controls[field].controls.forEach((arrayItem) => {
                                const existedId = arrayItem.value;
                                if (existedId) {
                                    request.relationships[field].data.push({
                                        id: existedId,
                                        type: config.fieldConfig[field].entityType,
                                    });
                                }
                            });
                        }
                    } else {
                        const existedId = formValue[field];
                        request.relationships[field] = {
                            data: existedId
                                ? {
                                      id: existedId,
                                      type: config.fieldConfig[field].entityType,
                                  }
                                : null,
                        };
                    }
                } else {
                    if (field === 'id') return;
                    if (config?.fieldConfig[field]?.fieldType === 'combination') {
                        request.attributes = {
                            ...request.attributes,
                            ...formValue[field],
                        };
                    } else {
                        let value = formValue[field];
                        // if we have property valueToSendWhenFieldIsInvalid in the form settings for the particular field
                        // we must send specified value instead of invalid one
                        if (
                            config?.fieldConfig[field] &&
                            config?.fieldConfig[field].valueToSendWhenFieldIsInvalid !== undefined
                        ) {
                            if (formGroup.controls[field].status === 'INVALID') {
                                value = config?.fieldConfig[field].valueToSendWhenFieldIsInvalid;
                            }
                        }
                        request.attributes[field] = value;
                    }
                }
            });
        };

        addFieldsToRequest(form.$config.fields, form);

        const configGroups = (groups, parentForm = form) => {
            if (!groups) return;
            groups?.forEach((group) => {
                addFieldsToRequest(group.fields, parentForm.get(group.id));
                configGroups(group.groups, parentForm.get(group.id));
            });
        };
        configGroups(form.$config.groups, form);
        return { request, haveUnfinishedChildCreation, included };
    }

    save(form: EntityFormGroup = this.form) {
        const now = new Date().getTime();
        const existedId = form.$config.id;
        const formId = existedId || form.$config.desiredId || uuidv4();
        const { request, haveUnfinishedChildCreation, included } = this.generateSaveRequest(
            form,
            existedId ? 'change' : 'add',
            formId,
        );
        // console.log('save form', form, request, included, haveUnfinishedChildCreation);

        if (haveUnfinishedChildCreation) {
            return EMPTY;
        }

        // console.log('Save entity request', { data: request });
        const observable = existedId
            ? this.srv.saveEntity$(
                  form.$config.entityType,
                  existedId,
                  { data: request, included },
                  form.$config.include,
              )
            : this.srv.createEntity$(
                  form.$config.entityType,
                  { data: request, included },
                  form.$config.include,
              );

        this._setSavingStatus('process');
        this._lastSaveTimestamp = now;
        return observable.pipe(
            map((response) => {
                form.$config.id = formId;

                this.updateInnerEntitiesIds(form, response);

                this._setSavingStatus('success');
                this.storageService.setItem(form.$config.entityType, response, form.$config.id);

                if (existedId) return response;

                // if entity was just created

                if (this.form.$config.reflectIdInUrl) {
                    this.router.navigateByUrl(
                        this.router.url.replace('/new', `/${form.$config.id}`),
                        {
                            replaceUrl: true,
                        },
                    );
                }
                if (this.form.$config.reflectIdInUrl || this.form.$config.updateAfterCreation) {
                    this.updateForm();
                }

                this.entityCreated$.next(true);
                return response;
            }),
            catchError((e) => {
                const needProcess409 = e?.error?.errors?.filter(
                    (v) => v.status === '409' && (!v.details || v.details !== 409),
                );
                if (e.status === 409 && !needProcess409) {
                    this.updateInnerEntitiesIds(form);
                    this._setSavingStatus('success');
                    return this.entityService.getEntity$(form.$config.entityType, form.$config.id, {
                        forceLoad: true,
                    });
                } else {
                    if (needProcess409) {
                        const message = this.srv.generate409ErrorMessage(e);
                        this.toastService.create({
                            titleKey: 'toast.apiConflictError.title',
                            messageKey: 'toast.apiConflictError.message',
                            messageParams: { message },
                            type: 'error',
                        });
                    }
                    this._setSavingStatus('error');
                }
                return of(null);
            }),
            shareReplay(SHARE_REPLAY_SETTINGS),
        );
    }

    updateInnerEntitiesIds(form, response = null) {
        const updateIds = (fields, formGroup) => {
            const config = form.$config;
            fields
                .filter(
                    (field) =>
                        config?.fieldConfig[field]?.isInnerEntity &&
                        config?.fieldConfig[field]?.entityType,
                )
                .forEach((field) => {
                    if (config.fieldConfig[field].type === 'array') {
                        formGroup.controls[field].controls.forEach((item) => {
                            if (!item.$config.desiredId || item.$config.id) return;

                            const existInResponse =
                                !response ||
                                response.$snapshot[field].findIndex(
                                    (v) => v.id === item.$config.desiredId,
                                );
                            item.$config.id = existInResponse > -1 ? item.$config.desiredId : null;
                        });
                    } else if (config.fieldConfig[field].type === 'object') {
                        if (
                            !formGroup.controls[field].$config.desiredId ||
                            formGroup.controls[field].$config.id
                        )
                            return;

                        const existInResponse =
                            !response ||
                            response.$snapshot[field]?.id ===
                                formGroup.controls[field].$config.desiredId;
                        formGroup.controls[field].$config.id = existInResponse
                            ? formGroup.controls[field].$config.desiredId
                            : null;
                    }
                });
        };

        updateIds(form.$config.fields, form);

        const configGroups = (groups, parentForm = form) => {
            if (!groups) return;
            groups?.forEach((group) => {
                updateIds(group.fields, parentForm.get(group.id));
                configGroups(group.groups, parentForm.get(group.id));
            });
        };
        configGroups(form.$config.groups, form);
    }

    updateForm(forceLoadEntity = true, configProperties = null) {
        if (configProperties) {
            this.form.$config = {
                ...this.form.$config,
                ...configProperties,
            };
        }
        this.formSettings$.next([this.form, forceLoadEntity]);
    }

    protected _setSavingStatus(status: FormSavingStatus['status']) {
        this._saving$.next(
            status
                ? {
                      status,
                      time: moment.utc().valueOf(),
                  }
                : null,
        );
    }

    delete(entityType = this.form.$config.entityType, id = this.form.$config.id) {
        return this.entityService.deleteEntity$(entityType, id);
    }

    delete$(
        entityType = this.form.$config.entityType,
        id = this.form.$config.id,
    ): Observable<EntData> {
        return this.delete(entityType, id);
    }

    addEntityToFormArray(formArray: EntityFormArray, value: any = null, emitEvent = true) {
        const formGroup = this.initInnerFormGroup(formArray.$config);
        if (value) {
            formGroup.patchValue(value);
        }
        formArray.push(formGroup, { emitEvent });

        // чтобы не было проблем с обновлением
        if (!emitEvent) {
            this._setPreviousFormValue();
        }
    }

    addToFormArray(formArray: EntityFormArray, value: any = null, config: any = null) {
        const control = new EntityFormControl(value);
        control.$config = config || {};
        if (value === null) {
            control.$config.desiredId = uuidv4();
        }
        const emitEvent = !!value;
        formArray.push(control, { emitEvent });

        // чтобы не было проблем с обновлением
        if (!emitEvent) {
            this._setPreviousFormValue();
        }
    }

    updateFormArrayItem(
        formArray: EntityFormArray,
        index: number,
        newValue: any = null,
        emitEvent = true,
    ) {
        formArray.at(index).setValue(newValue, { emitEvent });

        // чтобы не было проблем с обновлением
        if (!emitEvent) {
            this._setPreviousFormValue();
        }
    }

    updateFormArrayItems(formArray: EntityFormArray, indexes: number[], newValue: any = null) {
        indexes.forEach((index) => {
            formArray.at(index).setValue(newValue);
        });
    }

    updateFormField(field, value, formGroup = this.form) {
        if (!formGroup.get(field)) return;
        formGroup.get(field).setValue(value);
    }

    updateFormControlRequired(formControl: EntityFormControl, newRequiredValue, emitEvent = true) {
        formControl.$config.required.set(newRequiredValue);
        formControl.updateValueAndValidity({ emitEvent });
    }

    removeFromFormArray(formArray: EntityFormArray, index = 0, emitEvent = true) {
        formArray.removeAt(index, { emitEvent });

        // чтобы не было проблем с обновлением
        if (!emitEvent) {
            this._setPreviousFormValue();
        }
    }

    removeFromFormArrayByValue(
        formArray: EntityFormArray,
        value: string | EntDescription | null,
        emitEvent = true,
    ) {
        let i: number;
        if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
            i = formArray.value.findIndex((v) => value.id === v.id);
        } else {
            i = formArray.value.findIndex((v) => v === value);
        }
        this.removeFromFormArray(formArray, i, emitEvent);
    }

    clearFormArray(formArray: EntityFormArray) {
        formArray.setValue([]);
    }

    setChildStatusError(formControl: AbstractControl, status) {
        let errors = { ...formControl.errors };
        if (status === 'INVALID') {
            errors.childStatusError = status;
        } else if (formControl.errors) {
            delete errors.childStatusError;
        }
        formControl.setErrors(Object.keys(errors).length ? errors : null);
    }

    setChildSavingStatus(formControl: AbstractControl, status) {
        if (formControl instanceof EntityFormControl) {
            (formControl as EntityFormControl).$savingStatus = status;
        }
        if (formControl instanceof EntityFormGroup) {
            (formControl as EntityFormGroup).$savingStatus = status;
        }
        this._needUpdateSavingStatus$.next(true);
    }

    setFormSettings(config: FormSettings, forceLoadEntity = true) {
        this._previousFormValue = null;
        this._isInited = false;
        this.transitDone$ = new Subject();
        if (!config.validationSchema || !config.validationSchema.default) {
            config.validationSchema = {
                ...config.validationSchema,
                default: 'schema_update',
            };
        }
        this.transiting = new TemplateObservable<boolean>(false);
        this._setSavingStatus(null);
        this.form = new EntityFormGroup({});
        this.form.$config = config;
        this.updateForm(forceLoadEntity);
    }

    signEntity(context = null) {
        this.documentService
            .createGroupToSignBySettings([
                {
                    entityType: this.form.$config.entityType,
                    entityId: this.form.$config.id,
                    contextLabel: context,
                },
            ])
            .pipe(
                tap(() => {
                    this.updateForm();
                }),
                take(1),
            )
            .subscribe();
    }

    protected makeRedirectAfterTransit(
        transitName: string,
        transtRedirectTemplates: { [index: string]: string },
    ): boolean {
        const tplNames = Object.keys(transtRedirectTemplates);
        const tplName = tplNames.find((tpl) => transitName.indexOf(tpl) > -1);
        if (!tplName) return false;
        this.router.navigateByUrl(
            transtRedirectTemplates[tplName].replace('#id', this.form.$config.id),
        );
        return true;
    }

    transitEntity(transitSettings: { url: string; transit: string; payload: any; hint: string }[]) {
        this.transiting.set(true);
        this.entityService
            .transitEntity$(transitSettings)
            .pipe(
                tap((r) => {
                    this.transiting.set(false);
                    if (!r && !this.form.$config.emitTransitDoneAfterEmptyTransitResult) return;
                    if (!r) {
                        transitSettings.forEach((setting) => {
                            this.transitDone$.next(setting.transit);
                        });
                        return;
                    }
                    let needUpdate = false;
                    transitSettings.forEach((setting) => {
                        if (
                            this.form.$config &&
                            (!this.form.$config.clearFormAfterTransitions ||
                                this.form.$config.clearFormAfterTransitions.indexOf(
                                    setting.transit,
                                ) === -1)
                        ) {
                            needUpdate = true;
                        }
                        if (this.form.$config?.transitRedirectTemplates) {
                            const haveRedirect = this.makeRedirectAfterTransit(
                                setting.transit,
                                this.form.$config.transitRedirectTemplates,
                            );
                            if (haveRedirect) return;
                        }
                        this.transitDone$.next(setting.transit);
                    });
                    if (needUpdate) this.updateForm();
                }),
                catchError((error) => {
                    if (error) {
                        this.toastService.create({
                            titleKey: 'toast.transitError.title',
                            messageKey: error
                                ? 'toast.transitError.message'
                                : 'toast.transitError.signMessage',
                            messageParams: {
                                message: this.srv.generateErrorMessage(error),
                            },
                            type: 'error',
                        });
                    }
                    this.transiting.set(false);

                    return of(null);
                }),
                take(1),
            )
            .subscribe();
    }

    static getPropertiesFromConfig(instance: any, config: FormFieldConfig) {
        if (!config) return;

        ['required'].forEach((property) => {
            if (`${property}$` in instance) {
                instance[`${property}$`] = config[property] ? config[property].$ : of(false);
            }
        });

        ['entityType', 'maxLength', 'titleKey', 'tooltipKey', 'noteKey', 'options'].forEach(
            (property) => {
                if (property in instance) {
                    instance[property] =
                        config[property] !== undefined ? config[property] : instance[property];
                }
            },
        );

        if (config.anySettings) {
            Object.keys(config.anySettings).forEach((property) => {
                if (property in instance) {
                    instance[property] =
                        config.anySettings[property] !== undefined
                            ? config.anySettings[property]
                            : instance[property];
                }
            });
        }
    }

    mergeSavingStatuses(
        currentStatus: FormSavingStatus,
        foundStatus: FormSavingStatus,
    ): FormSavingStatus {
        if (!foundStatus) return currentStatus;
        if (!currentStatus) return foundStatus;
        if (currentStatus.status === 'error') return currentStatus;
        if (foundStatus.status === 'error') return foundStatus;
        if (currentStatus.status === 'process') return currentStatus;
        if (foundStatus.status === 'process') return foundStatus;
        return {
            status: 'success',
            time: Math.max(foundStatus.time, currentStatus.time),
        };
    }

    checkFormSavingStatus(
        formControl: EntityAbstractControl,
        initialStatus: FormSavingStatus,
    ): FormSavingStatus {
        let status = initialStatus;

        if (status?.status === 'error') return;

        if (formControl instanceof EntityFormControl) {
            status = this.mergeSavingStatuses(status, formControl.$savingStatus);
        }

        if (formControl instanceof EntityFormGroup) {
            Object.keys(formControl.controls).forEach((control) => {
                status = this.checkFormSavingStatus(
                    formControl.controls[control] as EntityAbstractControl,
                    status,
                );
            });
        }

        if (formControl instanceof EntityFormArray) {
            for (let control of (formControl as EntityFormArray).controls) {
                status = this.checkFormSavingStatus(control as EntityAbstractControl, status);
            }
        }

        return status;
    }

    getFieldChanges$(field) {
        return this.form$.pipe(
            filter((form) => !!form && !!form.get(field)),
            switchMap((form) =>
                form.get(field).valueChanges.pipe(startWith(form.get(field).value)),
            ),
        );
    }
}
