import { ViewportScroller } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnInit,
    Output,
    Renderer2,
    TemplateRef,
    ViewEncapsulation,
} from '@angular/core';
import { UNDERCONSTRUCTION_TITLE } from '@bazis/configuration.service';

interface TabItem {
    id: string | number;
    title?: string;
    titleKey?: string;
    titleParams?: any;
    entityType?: string;
    routerLink?: string;
    isExactLink?: boolean;
    disabled?: boolean;
    // name иконки или src
    hasBadge?: boolean;
    icon?: string;
    iconPosition?: 'start' | 'end';
    underconstruction?: boolean;
    hidden?: boolean;
}

// возможно надо будет предусмотреть для моб. устройств логику слайда табов в строке, когда они не помещаются в строку.
// еще лучше если для свайпа под мобильные сделать директиву. Так как может понадобится во многих компонентах

@Component({
    selector: 'bazis-tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['tabs.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsElement implements OnInit {
    @Input()
    tabList: TabItem[];

    @Input()
    selectedTab: string | number;

    @Input()
    hideByZeroCount = false;

    @Input()
    needScroll = true;

    @Input() tabTemplate: TemplateRef<any>;

    @Input() hasMore = false;

    @Input() forceChangeCounter: number;

    @Input() moreInsideItemsCounter = 0;

    @Input() underconstructionKey = UNDERCONSTRUCTION_TITLE.section;

    @Input() adaptiveMode: 'scroll' | 'useMore' = 'scroll';

    @Output()
    selectedTabChange = new EventEmitter();

    @HostListener('document:click', ['$event.target'])
    public onClick(targetElement) {
        const clickedOutside = targetElement !== this._elementRef.nativeElement;
        // console.log('targetElement', targetElement, clickedOutside);
        if (clickedOutside) {
            this.showMore = false;
        }
    }

    @HostBinding('class') get tabsClass() {
        return {
            [`bazis-tabs_${this.adaptiveMode}`]: !!this.adaptiveMode,
        };
    }

    showMore = false;

    constructor(
        private viewportScroller: ViewportScroller,
        private _elementRef: ElementRef,
        private renderer: Renderer2,
    ) {}

    ngOnInit() {
        this.renderer.listen(this._elementRef.nativeElement, 'click', (event) => {
            this.showMore = false;
        });
    }

    selectTab(tab: string | number) {
        // console.log('selectTab', this.selectedTab, tab);
        this.selectedTab = tab;
        this.selectedTabChange.emit(this.selectedTab);

        if (!this.needScroll) return;
        // setTimeout необходим, чтобы не приходилось кликать дважды на кнопку, если передано Output значение
        // данный баг двойного клика наблюдается в FF MAC OS
        setTimeout(() => this.viewportScroller.scrollToPosition([0, 0]), 0);
    }

    swipeLeft(event) {
        // console.log('swipe left', event);
    }

    swipeRight(event) {
        // console.log('swipe right', event);
    }

    toggleMoreTab(event) {
        event.stopPropagation();
        event.preventDefault();
        this.showMore = !this.showMore;
    }
}
